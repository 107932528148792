<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
import WelcomeWizard from './components/WelcomeWizard.vue';
export default {
  components: { WelcomeWizard },
    name: "WizardRenewedComponent"
};
</script>
